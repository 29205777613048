import React, { useEffect } from "react";
import useTitle from "../hooks/useTitle";

const RpmPage = () => {
  const title = useTitle()

  useEffect(() => {
    title("RPM")
  }, [])
  return (
    <>
      {/* Risk Profile start */}
      <div className="container py-3 text-center">
        <h3 className="position-relative d-inline-block text-primary">
          What is Risk Profile?
        </h3>
        <p className="text-center py-5">
          The <b>investment advice</b> and asset allocation for an
          <b>
            investor would have to be customized to the ability and willingness
            of the investor to assume risk
          </b>
          . This is determined by a risk profiling exercise, which seeks to
          assess the attitude towards risk and possible loss in the portfolio
          and the willingness to pursue an investment plan, after understanding
          the underlying risks.
        </p>
      </div>
      {/* Risk Profile end */}
      {/* Risk Info Cards start */}
      <div className="container-fluid py-2">
        <div className="container text-center">
          <div className="row">
            <div className="col-xl-4 col-lg-4 py-5">
              <div className="card border-0 rounded-3 shadow">
                <div className="card-body" style={{ overflow: "hidden" }}>
                  <h4 className="card-title py-3 text-primary">INFORMATION</h4>
                  <p className="card-text p-2 text-justify">
                    As SEBI registered investment adviser we obtain such
                    information from clients as it is necessary for the purpose
                    of giving investment advice. This information includes age,
                    investment objective, investment horizon, income details,
                    existing assets and liabilities and risk appetite.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-5">
              <div className="card rounded-3 border-0 shadow">
                <div className="card-body" style={{ overflow: "hidden" }}>
                  <h4 className="card-title py-3 text-primary">
                    RISK ASSESSMENT
                  </h4>
                  <p className="card-text p-2 text-justify">
                    Muhurat Investing have a process for assessing the risk that
                    a client is willing and able to take. And we inform clients
                    about their Risk profile after risk assessment.
                    <br />
                    <span className="small">
                      1. Assessing a client’s capacity towards Risk,
                      <br />
                      2. Identifying client attitude towards Risk.
                      <br />
                      3. Appropriately interpreting client responses to
                      questions and not attributing inappropriate weight to
                      certain answers.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-5">
              <div className="card rounded-3 border-0 shadow">
                <div className="card-body" style={{ overflow: "hidden" }}>
                  <h4 className="card-title py-3 text-primary">
                    RISK PROFILING TOOLS
                  </h4>
                  <p className="card-text p-2  text-justify">
                    Muhurat Investing use questionnaires to generate risk
                    appetite scores of clients. We ensure that questionnaires is
                    fit for the purpose and any limitations have been identified
                    and mitigated. Our questionnaire is neither too complex nor
                    misleading for the client to understand and express a fair
                    opinion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5">
            <p className="text-center">
              <b>
                Clients' financial risk tolerance - attitudes, values,
                motivations, preferences and experiences,
              </b>
              is measured with a risk profile. The
              <b>risk profile questionnaire</b> helps in understanding the
              <b>risk tolerance levels of a client</b>. Risk tolerance is the
              assumed level of risk that a client is willing to accept.
            </p>
          </div>
        </div>
      </div>
      {/* Risk Info Cards end */}
      {/* Risk Tolerance start */}
      <div className="container my-5 rounded-3 bg-hero shadow">
        <h4 className="text-dark text-center py-5">
          Financial Risk Tolerance Can Be Split Into Two Parts
        </h4>
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="card shadow rounded-3 border-0">
              <div className="card-body">
                <h5 className="card-title text-center">
                  1. Risk capacity: The ability to take the risk
                </h5>
                <p className="card-text py-5">
                  This relates to the client’s financial circumstances and their
                  investment goals. Generally speaking, a client with a higher
                  level of wealth and income (relative to any liabilities they
                  have) and a longer investment term will be able to take more
                  risk, giving them a higher risk capacity.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 mt-xl-0 mt-lg-0 mt-md-5 mt-5">
            <div className="card shadow rounded-3 border-0">
              <div className="card-body">
                <h5 className="card-title text-center">
                  2. Risk attitude: the willingness to take the risk
                </h5>
                <p className="card-text py-5">
                  Risk attitude has more to do with the individual's psychology
                  than with their financial circumstances. Some clients will
                  find the prospect of volatility in their investments and the
                  chance of losses distressing to think about. Others will be
                  more relaxed about those issues.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="py-5">
      <img src="#" class="img-fluid mx-auto d-block" alt="img" />
  </div> */}
        <div className="p-5">
          <p className="text-center">
            Risk tolerance is typically measured using questionnaires that
            estimate the ability and willingness to take risks. The responses of
            investors are converted into a score that may classify them under
            categories that characterize their risk preferences. Consider the
            following classification
          </p>
        </div>
      </div>
      {/* Risk Tolerance end */}
      {/* Conservative investers cards start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 py-5">
              <div
                className="card border-0 rounded-3 shadow"
                style={{ overflow: "hidden" }}
              >
                <div className="card-body">
                  <h4 className="card-title py-3 text-dark text-center">
                    Conservative Investors
                  </h4>
                  <ul
                    className="list-group py-4"
                    style={{ fontSize: "14px !important" }}
                  >
                    <li className="list-group-item text-justify">
                      Do not like to take risk with their investments. Typically
                      new to risky instruments.
                    </li>
                    <li className="list-group-item text-justify">
                      Prefer to keep their money in the bank or in safe income
                      yielding instruments.
                    </li>
                    <li className="list-group-item text-justify">
                      May be willing to invest a small portion in risky assets
                      if it is likely to be better for the longer term.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-5">
              <div
                className="card border-0 rounded-3 shadow"
                style={{ height: "auto !important" }}
              >
                <div className="card-body">
                  <h4 className="card-title py-3 text-dark text-center">
                    Moderate Investors
                  </h4>
                  <ul
                    className="list-group py-4"
                    style={{ fontSize: "14px !important" }}
                  >
                    <li className="list-group-item text-justify">
                      May have some experience of investment, including
                      investing in risky assets such as equities.
                    </li>
                    <li className="list-group-item text-justify">
                      Understand that they have to take investment risk in order
                      to meet their long-term goals.
                    </li>
                    <li className="list-group-item text-justify">
                      Are likely to be willing to take risk with a part of their
                      available assets.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 py-5">
              <div
                className="card border-0 rounded-3 shadow"
                style={{ height: "auto !important" }}
              >
                <div className="card-body">
                  <h4 className="card-title py-2 text-dark text-center">
                    Aggresive Investors
                  </h4>
                  <ul
                    className="list-group py-4"
                    style={{ fontSize: "14px !important" }}
                  >
                    <li className="list-group-item text-justify">
                      Are experienced investors, who have used a range of
                      investment products in the past, and who may take an
                      active approach to managing their investments?
                    </li>
                    <li className="list-group-item text-justify">
                      Willing to take on investment risk and understand that
                      this is crucial to generating long term return.
                    </li>
                    <li className="list-group-item text-justify">
                      Willing to take risk with a significant portion of their
                      assets.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className=" text-center">
              The risk preferences of the investor are taken into account while
              constructing an investment portfolio.
            </p>
          </div>
        </div>
      </div>
      {/* Conservative investers cards end */}
      {/* Suitability start */}
      <div className="container my-5 border-radius-new shadow">
        <h3 className="text-dark text-center py-5">Suitability</h3>
        <ul className="list-group">
          <li className="list-group-item active">
            As SEBI registered investment adviser we ensure the following
          </li>
          <li className="list-group-item">
            • Investment advice and related investments are appropriate to the
            client’s risk profile.
          </li>
          <li className="list-group-item">
            • Muhurat Investing Investment Advisor has a documented process for selecting products
            based on client’s investment objectives and financial situation.
          </li>
          <li className="list-group-item">
            • We always understand the nature and risks of products or assets
            selected for clients.
          </li>
          <li className="list-group-item">
            • We always have a reasonable basis for believing that a
            recommendation or transaction entered into:
          </li>
          <ul className="list-group">
            <li className="list-group-item">
              a. meets the client’s investment objectives.
            </li>
            <li className="list-group-item">
              b. is such that the client is able to bear any related investment
              risks consistent with its investment objectives and risk
              tolerance.
            </li>
            <li className="list-group-item">
              c. is such that the client has the necessary experience and
              knowledge to understand the risks involved in the transaction.
            </li>
          </ul>
        </ul>
        <div className="px-5 py-4">
          <p className="text-center">
            When advice is given to a client to purchase a complex financial
            product, such a recommendation or advice must be based upon a
            reasonable assessment that the structure and risk-reward profile of
            the financial product is consistent with clients experience,
            knowledge, investment objectives, risk appetite, and risk capacity
          </p>
        </div>
        <div className="px-5 py-4">
          <p className="text-center">
            <b className="text-danger">NOTE* :-</b>{" "}
            <b className="text-info">
              Find your questainer below. All fields are mandatory.
            </b>
          </p>
        </div>
      </div>
      {/* Suitability end */}
      {/*--------------- Form start -----------*/}
      <div className="container-fluid">
        <div className="container">
          <h2 className="text-center py-5">Risk Profiling Form Test</h2>
          <form id="riskForm" name="abcd" className="clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="riskname"
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Father's Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fname"
                  id="riskfname"
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Email Id</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="exampleInputEmail1"
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>PAN No</label>
                <input
                  type="text"
                  className="form-control"
                  name="panno"
                  id="paninput"
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Date Of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  id="dobinput"
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 form-group">
                <label>Gender</label>
                <div className="input-container">
                  <select className="form-control" name="gender1" id="gender1">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-12 form-group">
                <label>Aadhar Number</label>
                <input
                  type="number"
                  className="form-control"
                  name="aadhar"
                  id="ikycuid1"
                />
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border p-4 rounded-lg">
                <label>1. What is your age group?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    id="q1"
                    name="agegroup"
                    className=""
                    required="required"
                    defaultValue="18 to 35 Years"
                  />
                  18 to 35 Years
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    id="q1"
                    name="agegroup"
                    className="ml-5"
                    required="required"
                    defaultValue="36 to 45 Years"
                  />
                  36 to 45 Years
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    id="q1"
                    name="agegroup"
                    className="ml-5"
                    required="required"
                    defaultValue="46 to 55 Years "
                  />
                  46 to 55 Years <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    id="q1"
                    name="agegroup"
                    className=""
                    required="required"
                    defaultValue="56 to 65"
                  />
                  56 to 65
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    id="q1"
                    name="agegroup"
                    className="ml-5"
                    required="required"
                    defaultValue="66+"
                  />
                  66+
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border p-4 rounded-lg">
                <label>2. What is Investment Goal ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q2"
                    id="q2"
                    required="required"
                    defaultValue="Capital_Appreciation"
                  />
                  Capital Appreciation
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q2"
                    id="q2"
                    required="required"
                    className="ml-5"
                    defaultValue="Regular_Income"
                  />
                  Regular Income
                  <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q2"
                    id="q2"
                    required="required"
                    className=""
                    defaultValue="both"
                  />
                  Capital Appreciation and Regular Income
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>3. What is your Proposed Investment Amount ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q3"
                    required
                    className=""
                    id="q3"
                    defaultValue="50000 to 1 lacs"
                  />
                  50000 to 1 lacs
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q3"
                    required="required"
                    id="q3"
                    className="ml-5"
                    defaultValue="1 to 2 lacs"
                  />
                  1 to 2 lacs
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q3"
                    required="required"
                    className="ml-5"
                    id="q3"
                    defaultValue="2-5 lacs "
                  />
                  2-5 lacs <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q3"
                    required="required"
                    className=""
                    id="q3"
                    defaultValue="5-10 lacs"
                  />
                  5-10 lacs
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q3"
                    required="required"
                    className="ml-5"
                    id="q3"
                    defaultValue="> 10 lacs"
                  />
                  &gt; 10 lacs
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>4. What is your Preferred Investment type ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q4"
                    required="required"
                    id="q4"
                    className=""
                    defaultValue="Long_term"
                  />
                  Long term Holdings
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q4"
                    required="required"
                    id="q4"
                    className="ml-5"
                    defaultValue="Short_term"
                  />
                  Short term Holdings
                  <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q4"
                    required="required"
                    id="q4"
                    className=""
                    defaultValue="Intraday"
                  />
                  Intraday/Overnight holdings
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q4"
                    required="required"
                    id="q4"
                    className="ml-5"
                    defaultValue="Positional/Derivatives"
                  />
                  Positional/Derivatives
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>5. What is yours Yearly Income Range ?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q5"
                    required="required"
                    id="q5"
                    className=""
                    defaultValue="50000_To_1_lacs"
                  />
                  Below 1 lac
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q5"
                    required="required"
                    id="q5"
                    className="ml-5"
                    defaultValue="1-3_lacs"
                  />
                  1-3 lac
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q5"
                    required="required"
                    id="q5"
                    className="ml-5"
                    defaultValue="3-5_lacs"
                  />
                  3-6 lac
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q5"
                    required="required"
                    id="q5"
                    className="ml-5"
                    defaultValue="Above_5_lacs"
                  />
                  6-10 lac
                  <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q5"
                    required="required"
                    id="q5"
                    className=""
                    defaultValue="25_lac"
                  />
                  &gt;10 lac
                </div>
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4">
                <h6 className="text-center">
                  6. Occupation (Please select the appropriate)
                </h6>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    name="q7"
                    required="required"
                    id="q7"
                    className=""
                    defaultValue="Private_sector_service"
                  />
                  Private sector service
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    name="q7"
                    id="q7"
                    required="required"
                    className="ml-5"
                    defaultValue="Public_sector"
                  />
                  Public sector/Government sector
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    id="q7"
                    name="q7"
                    required="required"
                    className="ml-5"
                    defaultValue="Business"
                  />
                  Business
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    name="q7"
                    id="q7"
                    required="required"
                    className="ml-5"
                    defaultValue="Retired/Housewife,/Student "
                  />
                  Retired/Housewife,/Student
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    id="q7"
                    name="q7"
                    required="required"
                    className="ml-5"
                    defaultValue="Professional"
                  />
                  Professional /Dealer /Self Employed
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    id="q7"
                    name="q7"
                    required="required"
                    className="ml-5"
                    defaultValue="Others"
                  />
                  Others
                </div>
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4">
                <h4 className="text-center mb-5">
                  {" "}
                  <strong>Sources of Income</strong>{" "}
                </h4>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 border rounded-lg p-4">
                    <label>7. Primary Source</label>
                    <div className="mt-4">
                      <input
                        type="radio"
                        // onclick="countRisknew('q_20')"
                        data-val={0}
                        name="9_a"
                        id="q9"
                        required="required"
                        className=""
                        defaultValue="Salary"
                      />
                      Salary
                      <input
                        type="radio"
                        // onclick="countRisknew('q_20')"
                        data-val={0}
                        name="9_a"
                        id="q9"
                        required="required"
                        className="ml-5"
                        defaultValue="Business"
                      />
                      Business
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 border rounded-lg p-4">
                    <label>8. Secondary Source</label>
                    <div className="mt-4">
                      <input
                        type="radio"
                        // onclick="countRisknew('q_20')"
                        data-val={0}
                        name="9_b"
                        id="q10"
                        required="required"
                        className=""
                        defaultValue="Royalties"
                      />
                      Royalties
                      <input
                        type="radio"
                        // onclick="countRisknew('q_20')"
                        data-val={0}
                        id="q10"
                        name="9_b"
                        required="required"
                        className="ml-5"
                        defaultValue="Rental"
                      />
                      Rental
                      <input
                        type="radio"
                        // onclick="countRisknew('q_20')"
                        data-val={0}
                        id="q10"
                        name="9_b"
                        required="required"
                        className="ml-5"
                        defaultValue="Dividend"
                      />
                      Dividend
                      <input
                        type="radio"
                        // onclick="countRisknew('q_20')"
                        data-val={0}
                        id="q10"
                        name="9_b"
                        required="required"
                        className="ml-5"
                        defaultValue="Others"
                      />
                      None
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  9. Approx Value of assets held like property, FD, Shares,
                  Mutual Fund etc?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q6"
                    required="required"
                    id="q6"
                    className=""
                    defaultValue="1_lacs"
                  />
                  &lt;1 lacs
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q6"
                    required="required"
                    id="q6"
                    className="ml-5"
                    defaultValue="1-2_lacs"
                  />
                  1-2 lacs
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q6"
                    id="sixth3"
                    required="q6"
                    className="ml-5"
                    defaultValue="2-5_lacs"
                  />
                  2-5 lacs
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q6"
                    id="sixth4"
                    required="q6"
                    className="ml-5"
                    defaultValue="5_lacs"
                  />
                  5-10 lacs
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q6"
                    id="sixth4"
                    required="q6"
                    className=""
                    defaultValue="5_lacs"
                  />
                  5-10 lacs
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>10. What is your Investment Experience?</label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="3_yr"
                  />
                  1 years
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="3_yr"
                  />
                  3 years
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q8"
                    id="q8"
                    required="required"
                    className="ml-5"
                    defaultValue="3-5_yr"
                  />
                  3-5 years
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q8"
                    id="q8"
                    required="required"
                    className="ml-5"
                    defaultValue="6-10_yr"
                  />
                  6-10 years
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="10_yr"
                  />
                  &gt;10 years
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  11. What is the option best describe your current stage of
                  life ?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="3_yr"
                  />
                  Single with few financial burdens
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="3_yr"
                  />
                  A couple without children preparing for future
                  responsibilities.
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="3-5_yr"
                  />
                  You are in a peak earning years and both are working and
                  secured by a lien on the property and debts are under control
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="6-10_yr"
                  />
                  Preparing For retirement for future goal
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q8"
                    id="q8"
                    required="required"
                    className=""
                    defaultValue="10_yr"
                  />
                  Retired , already receiving a government pension.
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  12. Count of financially dependents person on you?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q11"
                    id="q12"
                    required="required"
                    className=""
                    defaultValue="None"
                  />
                  None
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q11"
                    id="q12"
                    required="required"
                    className="ml-5"
                    defaultValue="1-3"
                  />
                  Between 1-3
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q11"
                    id="q12"
                    required="required"
                    className="ml-5"
                    defaultValue="4-6"
                  />
                  Between 4-6
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q11"
                    id="q12"
                    required="required"
                    className="ml-5"
                    defaultValue="4+"
                  />
                  Above 4 Members
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  13. I would start to worry about my investments if my
                  Investment value falls 50%
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q10"
                    required="required"
                    id="q11"
                    className=""
                    defaultValue="Keep investments as it is"
                  />
                  Keep investments as it is
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q10"
                    required="required"
                    id="q11"
                    className="ml-5"
                    defaultValue="Sell and Save cash"
                  />
                  Sell and Save cash
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q10"
                    required="required"
                    id="q11"
                    className="ml-5"
                    defaultValue="Wait till the market recovers and then sell"
                  />
                  Wait till the market recovers and then sell
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q10"
                    required="required"
                    id="q11"
                    className="ml-5"
                    defaultValue="I can add investment (money) if require"
                  />
                  I can add investment (money) if require
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  14. What is your expected rate of return from your
                  investments?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q12"
                    required="required"
                    id="q13"
                    className=""
                    defaultValue="Potential return of 6-10 % of investment "
                  />
                  Potential return of 6-10 % of investment
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q12"
                    required="required"
                    id="q13"
                    className="ml-5"
                    defaultValue="Potential return of 10-15 % of investment"
                  />
                  Potential return of 10-15 % of investment
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q12"
                    required="required"
                    id="q13"
                    className="ml-5"
                    defaultValue="Potential return of 15-20% of investment"
                  />
                  Potential return of 15-20% of investment
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q12"
                    required="required"
                    id="q13"
                    className=""
                    defaultValue="Potential return of more than 25% of investment "
                  />
                  Potential return of more than 25% of investment
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  15. What is your experience with investments in past?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q16"
                    required="required"
                    id="q16"
                    className=""
                    defaultValue="Very_Good"
                  />
                  Very Good
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q16"
                    required="required"
                    id="q16"
                    className="ml-5"
                    defaultValue="Good"
                  />
                  Good
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q16"
                    required="required"
                    id="q16"
                    className="ml-5"
                    defaultValue="Moderate"
                  />
                  Moderate
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q16"
                    required="required"
                    id="q16"
                    className="ml-5"
                    defaultValue="Bad"
                  />
                  Bad
                  <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    name="q16"
                    required="required"
                    id="q16"
                    className="radio"
                    defaultValue="very_Bad"
                  />
                  Very Bad
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  16. In order to achieve high returns I am willing to choose
                  high risk investments?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={9}
                    name="q18"
                    required="required"
                    className=""
                    id="q18"
                    defaultValue="Strongly_prefer"
                  />
                  Strongly prefer
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q18"
                    required="required"
                    className="ml-5"
                    id="q18"
                    defaultValue="Prefer"
                  />
                  Prefer
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q18"
                    required="required"
                    id="q18"
                    className="ml-5"
                    defaultValue="Neutral"
                  />
                  Neutral
                  <br />
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q18"
                    required="required"
                    id="q18"
                    className=""
                    defaultValue="Do_not_prefer"
                  />
                  Do not prefer
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q18"
                    required="required"
                    id="q18"
                    className="ml-5"
                    defaultValue="Strongly_Do_not_prefer"
                  />
                  Strongly do not prefer
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  17. what percentage of monthly income is allocated to pay off
                  debt (all EMIs)?
                </label>
                <div className="mt-4">
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={7}
                    name="q13"
                    required="required"
                    id="q14"
                    className=""
                    defaultValue="None"
                  />
                  None
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={5}
                    name="q13"
                    required="required"
                    id="q14"
                    className="ml-5"
                    defaultValue="Less than 15%"
                  />
                  Less than 15%
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={3}
                    name="q13"
                    required="required"
                    id="q14"
                    className="ml-5"
                    defaultValue="Between 15% to 35"
                  />
                  Between 15% to 35
                  <br />
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={1}
                    name="q13"
                    required="required"
                    id="q14"
                    className=""
                    defaultValue="Between 35% to 50"
                  />
                  Between 35% to 50
                  <input
                    type="radio"
                    // onclick="countRisknew('q_20')"
                    data-val={0}
                    name="q13"
                    required="required"
                    id="q14"
                    className="ml-5"
                    defaultValue=">50"
                  />
                  &gt;50
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label>
                  18. Please Selects, if applicable, for any of your authorized
                  signatories/ Promoters/ Partners/ Karta/ Trustees/ whole time
                  directors
                </label>
                <select name="directly" className="form-control mt-3">
                  <option value="NA">NA</option>
                  <option value="Civil Servant">Civil Servant</option>
                  <option value="Politician">Politician</option>
                  <option value="Current or former head of state">
                    Current or former head of state
                  </option>
                  <option value="Bureaucrat (Tax authorities, Foreign Services, IAS etc)">
                    Bureaucrat (Tax authorities, Foreign Services, IAS etc)
                  </option>
                  <option value="Current or former MP/MLA/MLC">
                    Current or former MP/MLA/MLC
                  </option>
                  <option value="Connected to any company/promoter group/ group of companies listed on any stock exchange">
                    Connected to any company/promoter group/ group of companies
                    listed on any stock exchange
                  </option>
                </select>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label htmlFor="exampleInputFile">
                  Client Signature&nbsp;:(Only jpg,png,jpeg Format File)
                </label>
                <div className="mt-4 form-group">
                  <input
                    type="file"
                    id="kycsignature"
                    name="nonsignature"
                    className="form-control py-1"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mt-5 border rounded-lg p-4">
                <label htmlFor="exampleInputFile">
                  Authorised Signature&nbsp;:(Only jpg,png,jpeg Format File)
                </label>
                <div className="mt-4 form-group">
                  <input
                    type="file"
                    id="kycphoto"
                    name="nonphoto"
                    className="form-control py-1"
                  />
                </div>
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4 form-group">
                <b>Your Total Risk Assessment Score</b>
                <br />
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="rp_score"
                  name="total_score"
                  readOnly=""
                />
              </div>
              <div className="col-12 mt-5 border rounded-lg p-4 form-group">
                <b>
                  Confirmation Code *&nbsp;:
                  <img id="image" src="#" alt="img" />
                </b>
                <input
                  name="captcha"
                  className="form-control mt-5"
                  placeholder="Please Enter Captcha"
                  type="text"
                  id="kyccaptcha"
                // onkeyup="return riskcaptcha1();"
                />
                <input
                  type="submit"
                  name="submit"
                  defaultValue="submit"
                  className="btn btn-dark buttons mt-5"
                  id="risk"
                // onclick="return SendRiskform()"
                />
              </div>
            </div>
          </form>
          <div>
            <p className="text-center p-5">
              <b className="text-danger">Note* : </b>Client’s with long term
              investment goal or capital appreciation as investment objectives
              are not accepted at The Muhurat-Investing Private Limited.
            </p>
          </div>
        </div>
      </div>
      {/*--------------- Form end -------------*/}
      {/* Analysis start */}
      <div className="container my-5">
        <h4 className="text-center">Analysis</h4>
        <p className="mt-3 text-center">
          In setting up an investment portfolio suitable for you, your financial
          adviser will ask you a series of questions about your financial and
          lifestyle goals. Using this information, plus details of your current
          assets, liabilities and income, your adviser can determine what level
          of risk or exposure you are prepared to tolerate in relation to
          fluctuations in the marketplace - and the level that makes sense for
          your stage in life. From this, an appropriate mix of assets can be
          allocated to your investment portfolio.
        </p>
      </div>
      {/* Analysis end */}
    </>
  );
};

export default RpmPage;
