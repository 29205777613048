export const firstTable = JSON.stringify([
    {
        name:"Directly from Investors",
        second:0,
        third:0,
        fourth:0,
        fifth:0,
        sixth:0,
        seven:0
    },
    {
        name:"SEBI (SCORES)",
        second:0,
        third:0,
        fourth:0,
        fifth:0,
        sixth:0,
        seven:0
    },
    {
        name:"Other Source (if any)",
        second:0,
        third:0,
        fourth:0,
        fifth:0,
        sixth:0,
        seven:0
    },
])

export const secondTable = JSON.stringify([
    {
        month:"JANUARY",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"FEBRUARY",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"MARCH",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"APRIL",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"MAY",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"JUNE",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"JULY",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"AUGUST",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"SEPTEMBER",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"OCTOBER",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"NOVEMBER",
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        month:"DECEMBER",
        first:"",
        Received:"",
        Resolved:"",
        Panding:""
    }
])

export const thirdTable = JSON.stringify([
    {
        year:2023,
        first:0,
        Received:0,
        Resolved:0,
        Panding:0
    },
    {
        year:"NA",
        first:"",
        Received:"",
        Resolved:"",
        Panding:""
    },
    {
        year:"NA",
        first:"",
        Received:"",
        Resolved:"",
        Panding:""
    },
])