import React from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../RoutePath";

const Footer = () => {
  return (
    <>
      <>
        {/* Footer Start */}
        <div
          className="container-fluid bg-dark text-light pb-5"
        >
          <div className="container pt-1">
            <div className="row g-5 pt-4">
              <div className="col-lg-2 col-md-6">
                <h3 className="text-white mb-4">Quick Links</h3>
                <div className="d-flex flex-column justify-content-start">
                  <Link className="text-light mb-2" to={RoutePath.HOME}>
                    <i className="bi bi-arrow-right text-primary me-2" />
                    Home
                  </Link>
                  <Link to={RoutePath.ABOUT} className="text-light mb-2">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    About Us
                  </Link>
                  <Link to={RoutePath.SERVICES} className="text-light mb-2">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    Our Services
                  </Link>
                  <Link to={RoutePath.EKYC} className="text-light mb-2">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    eKYC
                  </Link>
                  <Link to={RoutePath.RPM} className="text-light mb-2">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    RPM
                  </Link>
                  <Link to={RoutePath.COMPLAINTS} className="text-light mb-2">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    Complaints Status
                  </Link>
                  <Link to={RoutePath.BANK_DETAILS} className="text-light mb-2">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    Bank Details
                  </Link>
                  <Link to={RoutePath.CONTACT} className="text-light">
                    <i className="bi bi-arrow-right text-primary me-2" />
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <h3 className="text-white mb-4">SEBI Office Details:</h3>
                <div className="d-flex flex-column justify-content-start">
                  <p>
                    <b>SEBI Bhavan BKC Address :</b>
                  </p>
                  <p>
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <b>First Address :</b> Plot No.C4-A, 'G' Block Bandra-Kurla Complex, Bandra (East),
                    Mumbai - 400051, Maharashtra
                  </p>
                  <p>
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <b>Second Address :</b> 305, 3rd Floor, Satguru Parinay, AB Rd, Opposite C-21 Mall, Indore, Madhya Pradesh 452010
                  </p>
                  <p>
                    <i className="bi bi-telephone text-primary me-2" />
                    <b>Tel :</b>
                    +91-22-26449000 / 40459000
                  </p>
                  <p>
                    <i class="bi bi-printer text-primary me-2"></i>
                    <b> Fax :</b> +91-22-26449019-22 / 40459019-22
                  </p>
                  <p>
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <b>Email :</b> sebi@sebi.gov.in
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <h3 className="text-white mb-4">Get In Touch</h3>
                <p className="mb-2">
                  <i className="bi bi-person-fill text-primary me-2" />
                  Compliance officer:-Vishal Borasi
                </p>
                <p className="mb-2">
                  <i className="bi bi-person-fill text-primary me-2" />
                  Name : Vishal Borasi Proprietor Muhurat Investing - Investment Advisor
                </p>

                <p className="mb-2">
                  <i className="bi bi-geo-alt text-primary me-2" />
                  Registration No. INA000018692
                </p>
                <p className="mb-2">
                  <i className="bi bi-geo-alt text-primary me-2" />
                  13-14 Mukharji Nagar, Gali No 07 Panch Mori, INDORE, MADHYA PRADESH, 452015
                </p>
                <p className="mb-2">
                  <i className="bi bi-person-fill text-primary me-2" />
                  Contact Person : Vishal Borasi
                </p>
                <p className="mb-2">
                  <i className="bi bi-geo-alt text-primary me-2" />
                  Correspondence Address : 13-14 Mukharji Nagar, Gali No 07 Panch Mori, INDORE, MADHYA PRADESH, 452015
                </p>
                <p className="mb-2">
                  <i className="bi bi-envelope-open text-primary me-2" />
                  <a href="mailto:support@muhuratinvesting.com">support@muhuratinvesting.com</a>
                </p>
                <p className="mb-2">
                  <i className="bi bi-telephone text-primary me-2" />
                  <a href="tel:9584096836">+91 9584096836</a>
                </p>
                <p className="mb-0">
                  <i className="fa-regular fa-circle-check text-primary me-2" />
                  Validity : Dec 18, 2023 - Perpetual
                </p>
              </div>
              <div className="col-lg-2 col-md-6">
                <h3 className="text-white mb-4">Follow Us</h3>
                <div className="d-flex">
                  <Link
                    className="btn btn-lg btn-primary buttons  btn-lg-square rounded me-2"
                    to="#"
                  >
                    <i className="fab fa-twitter fw-normal" />
                  </Link>
                  <Link
                    className="btn btn-lg btn-primary buttons btn-lg-square rounded me-2"
                    to="https://www.facebook.com/muhuratinvesting/"
                  >
                    <i className="fab fa-facebook-f fw-normal" />
                  </Link>
                  <Link
                    className="btn btn-lg btn-primary buttons btn-lg-square rounded me-2"
                    to="#"
                  >
                    <i className="fab fa-linkedin-in fw-normal" />
                  </Link>
                  <Link
                    className="btn btn-lg btn-primary buttons btn-lg-square rounded"
                    to="https://www.instagram.com/muhuratinvesting/"
                  >
                    <i className="fab fa-instagram fw-normal" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-light py-4 bg-dark">
          <div className="container">
            <div className="row g-0">
              <div className="col-12 text-center text-md-start">
                <p className="mb-md-0">
                  <b className="text-danger">Note*</b>
                  &nbsp;- Investments in securities market are subject to market
                  risks. Read all the related documents carefully before
                  investing.
                </p>
                <h5 className="mt-3 text-danger">Investment Disclaimer</h5>
                <p className="mb-md-0">
                  Investment disclaimers explain that while a business may
                  provide financial advice, it is not responsible for the
                  consequences of acting on that advice. It can also act as a
                  past performance disclaimer, which informs your users that
                  past financial outcomes do not equal future results.
                </p>
                <h5 className="mt-3 text-danger">Disclosure</h5>
                <p className="mb-md-0">
                  All investments in Stock Markets, Commodity Markets and other
                  financial instruments traded here are subject to market risks
                  and the Returns may go up or down depending upon the factors
                  and forces affecting the securities market including the
                  fluctuations in the interest rates.
                </p>
                <ul>
                  <li>
                    KYC (Know Your Customer) and Risk Profiling/Risk
                    Assessment need to be completed before you can use any
                    services of MUHURAT INVESTING INVESTMENT ADVISOR. Even if
                    the subscription fees are paid, no services would be
                    rendered/activated to any customer before the completion
                    of KYC (Know Your Customer) and Risk Profiling
                    formalities. For KYC (Know Your Customer) formalities we
                    proceed with the information mentioned on the PAN Card
                    Number only.
                  </li>
                  <li>
                    There can be no assurance that an objective will be
                    achieved. The past performance of the
                    calls/recommendations given or managed by MUHURAT
                    INVESTING INVESTMENT ADVISOR and its affiliates is not
                    necessarily indicative of the future performance of the
                    Calls/Advice. The Service is not guaranteeing or assuring
                    any returns under any of the Services subscribed to
                    herewith.
                  </li>
                  <li>
                    The investments made in the Stock Markets, Commodity
                    Markets and/or in any of financial instruments are subject
                    to external risks. The floating rate investments are
                    subject to risks pertaining to Interest rate movement,
                    Spread Movement, Settlement and Liquidity.
                  </li>
                  <li>
                    MUHURAT INVESTING INVESTMENT ADVISOR will not be liable
                    for any, direct or indirect, consequential or incidental
                    damages or loss arising out of the use of the information
                    given on the website or through SMS.
                  </li>
                  <li>
                    MUHURAT INVESTING INVESTMENT ADVISOR generates all its
                    Advice/Recommendations via the science of Technical and
                    fundamental analysis. Our services are limited to
                    providing research-based trading signals.
                  </li>
                  <li>
                    MUHURAT INVESTING INVESTMENT ADVISOR is a SEBI registered
                    investment adviser with the registration number
                    (INA000018692).
                  </li>
                  <li>
                    MUHURAT INVESTING INVESTMENT ADVISOR does not provide any
                    execution based/PMS (Portfolio management) based services.
                    We only provide investment advice to customers on a
                    pre-paid subscription basis.
                  </li>
                  <li>
                    MUHURAT INVESTING INVESTMENT ADVISOR does not have
                    affiliations with any intermediary and hence does not
                    receive any remuneration or compensation of any form from
                    any other intermediary.
                  </li>
                  <li>
                    Investment/trading in the securities markets is subject to
                    market risk and MUHURAT INVESTING INVESTMENT ADVISOR
                    doesn’t offer any assured and guaranteed returns on any
                    services/products. MUHURAT INVESTING INVESTMENT ADVISOR
                    doesn’t provide any profit sharing services, guaranteed
                    services and services which are not mentioned in our
                    website, if in case any person tries to sell such services
                    please contact us on +919584096836 or mail us at
                    vishuborasi@gmail.com.
                  </li>
                  <li>
                    Registration granted by SEBI, membership of BASL, and
                    certification from NISM in no way guarantee the
                    performance of the intermediary or provide any assurance
                    of returns to investors.
                  </li>
                  <li>
                    Service charges must be paid only in company's bank
                    account.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid text-light py-4"
          style={{ background: "#051225" }}
        >
          <div className="container">
            <div className="row justify-content-between g-0">
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                <p className="mb-md-0">
                  SEBI Registration Number: INA000018692 <br />
                  BASL MEMBERSHIP NO. BASL2109
                </p>
              </div>
              <div className="col-md-4 text-center d-flex justify-content-center align-items-center">
                <div className="footer-links mb-2">
                  <Link to={RoutePath.INVESTOR_CHARTER} className="text-white p-2">Investor Charter <span className="ms-3">|</span></Link>
                  <Link to={RoutePath.PRIVACY} className="text-white p-2"> Privacy Policy <span className="ms-3">|</span></Link>
                  <Link to={RoutePath.DISCLAIMER} className="text-white p-2"> Disclaimer <span className="ms-3">|</span></Link>
                  <Link to={RoutePath.DISCLOSURE} className="text-white p-2"> Disclosure <span className="ms-3">|</span></Link>
                  <Link to={RoutePath.COMPLIANCE} className="text-white p-2"> Compliance <span className="ms-3">|</span></Link>
                  <Link to={RoutePath.CANCELLATION} className="text-white p-2">Cancellation & Refund</Link>
                </div>
              </div>
              <div className="col-md-4 text-center d-flex justify-content-center align-items-center">
                <p className="mb-md-0">
                  ©
                  <Link
                    className="text-white border-bottom"
                    to={RoutePath.HOME}
                  >
                    Muhurat-Investing
                  </Link>
                  . All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
      </>
    </>
  );
};

export default Footer;
